import AbstractFoundationI18n from "@entity/AbstractFoundationI18n";

/**
 * Class AbstractFoundation.
 * Entity de base
 *
 * @link       http://github.com/bssoufo
 * @author     Bruno Soufo <bssoufo@gmail.com>
 */
export default abstract class AbstractFoundation {
    public id: number;

    /**
     * Constructor default.
     * @param id
     */
    public constructor(id: number = 0) {
        this.id = id;
    }

    /**
     * Retourne pour l'entité this, le libellé à utiliser par exemple dans les champs input.
     *
     * @param locale
     */
    public abstract toString(locale?: string): string;
}

