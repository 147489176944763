import React, {FunctionComponent, useEffect} from "react";
import {VerifyEmailProps} from "@app/core/types/generic.interface";
import "../loader/loader.css";
import UserRepository from "@app/repository/user/UserRepository";
/**
 *
 * Modal utilisée dans toute l'application
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const VerifyEmail: FunctionComponent<VerifyEmailProps> = (props) => {

    const manager = new UserRepository(props.apiUrl)

    useEffect(()=>{
        manager.verify(props.code).then((e)=>{
            if(props.onVerifySuccess){
                props.onVerifySuccess(e)
            }
        }).catch((e)=>{
            if(props.onVerifySuccess){
                props.onVerifySuccess(e)
            }
        })
    },[])

    return (
        <React.Fragment></React.Fragment>
    )
};

export default VerifyEmail;
