import React, {FunctionComponent, useEffect, useState} from "react";
import {DIALOG_TYPE, HTTP_RESPONSE} from "@utils/constants";
import {useFormik} from "formik";
import "../loader/loader.css";
import * as Yup from "yup";
import LoadingContainer from "@components/utilities/LoadingContainer";
import {
  Row,
  Col,
  Form,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  FormFeedback, Alert
} from "reactstrap"
import {DialogInterface} from "@app/core/types/app.state";
import RoleRepository from "@repository/RoleRepository";
import DialogBoxComponent from "../utilities/DialogBoxComponent";
import AutocompleteSelectType from "../form/core/type/AutocompleteSelectType";
import PermissionRepository from "@app/repository/PermissionRepository";
import { withTranslation } from "react-i18next";
/**
 *
 * Modal utilisée dans toute l'application
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const EditRole: FunctionComponent<any> = (props: any): any => {
  const {apiUrl = "", onEditSuccess = {}, id = 0, apiPermissionUrl, apiRolePermissionUrl} = props;
  const [loading, setLoading] = useState(true);
  const [dialog, setDialog] = useState<DialogInterface>({
    show: false,
    onConfirm: () => {
    },
    handleClose: () => {
    },
    message: "",
    type: DIALOG_TYPE.ALERT
  });

  const manager = new RoleRepository(apiUrl,apiRolePermissionUrl);

  const [initialPermission, setInitialPermission] = useState([]);

  const permissionRepo = new PermissionRepository(apiPermissionUrl);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      id : null,
      name: '',
      description: '',
      permissions : []
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Veuillez entrer le nom")
    }),
    onSubmit: (values) => {
      setLoading(true)
      let tmp = values
      
      if(tmp.description){
      }else{
        tmp.description = ""
      }

      let added_permission = values.permissions.filter( (value, index, arr) => { 
        return initialPermission.indexOf(value) == -1;
      });

      let removed_permission = initialPermission.filter( (value, index, arr) => { 
        return values.permissions.indexOf(value) == -1;
      });

      removed_permission.forEach(e =>{
        manager.deletePermission(values.id, e.id);
      })
      
      delete tmp["permissions"]

      if(tmp.id){}
      else{
        delete tmp["id"]
      }
      
      manager.flush({...tmp }).then((response) => {
        added_permission.forEach(e =>{
          let tmpId: number = parseInt( response ? response.id : id );
          manager.addPermission({roleId : tmpId, permissionId: e.id});
        })
        setLoading(false);
        if (typeof onEditSuccess == 'function') {
          onEditSuccess(response);
        }
      }).catch(response => {
        catchError(response)
      })
  
    }
  });

  useEffect(() => {
    if (id) {
      setLoading(true);
      manager.findIncludeAll(id).then((item) => {
        setLoading(false);
        validation.setValues(item.items[0]);
        setInitialPermission(item.items[0].permissions ? item.items[0].permissions  : [] )
      }).catch((error)=>{
        catchError(error);
      })
    } else {
      setLoading(false);
    }
  }, []);

  /**
   * Gestion de la soumission du formulaire
   *
   * @param event
   * @returns {Promise<void>}
   */
  const handleSubmitForm = (event: any) => {
    event.preventDefault();
    validation.handleSubmit();
  };

  const catchError = (response: any) => {
    const currentDialog: DialogInterface = {
      show: true,
      onConfirm: () => closeDialogModal(),
      handleClose: () => closeDialogModal(),
      type: DIALOG_TYPE.ALERT
    };
    switch (response.status) {
      case HTTP_RESPONSE.HTTP_BAD_REQUEST: {
        currentDialog.type = DIALOG_TYPE.ALERT;
        break;
      }
      case HTTP_RESPONSE.HTTP_NOT_FOUND: {
        currentDialog.type = DIALOG_TYPE.ALERT;
        break;
      }
      case HTTP_RESPONSE.HTTP_INTERNAL_SERVER_ERROR: {
        currentDialog.type = DIALOG_TYPE.SERVER_ERROR;
        currentDialog.message = '';
        if (response.data.message) {
          currentDialog.message = response.data.message;
        }
        break;
      }
      default:
    }
    setDialog(currentDialog);
  };

  /**
   */
  const closeDialogModal = () => {
    setDialog({...dialog, show: false})
  };

  return (
    <React.Fragment>
      <LoadingContainer className="mb-4" loading={loading}>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle>{props.t("EDIT_ROLE")}</CardTitle>
                <CardSubtitle className="mb-3">
                {props.t("PLEASE_FILL_ROLE_DETAIL")}
                </CardSubtitle>
                <Form onSubmit={(e) => handleSubmitForm(e)}>
                  <div className="row">
                    <div className="col-md-8 col-12">
                        <div className="mb-3">
                            <Label className="form-label">{props.t("NAME")} <span className="text-danger">*</span> </Label>
                            <Input
                            id="name"
                            name="name"
                            className="form-control"
                            placeholder={props.t("NAME")}
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            />
                            {validation.touched.name && validation.errors.name ? (
                            <FormFeedback
                                type="invalid">{validation.errors.name}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">{props.t("DESCIPTION_")} </Label>
                            <Input
                            id="description"
                            name="description"
                            className="form-control"
                            placeholder={props.t("DESCIPTION_")}
                            type="textarea"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description || ""}
                            />
                        </div>
                        <div className="mb-3">
                          <Label
                              className="form-label">{props.t("PERMISSIONS_")}</Label>
                          <AutocompleteSelectType
                              id={"permissions"}
                              name={"permissions"}
                              multiple={true}
                              choiceAttr={"name"}
                              value={validation.values.permissions || []}
                              remoteChoices={(inputValue:any) => {return new Promise(resolve => resolve(permissionRepo.findAll( 1,10, inputValue).then((data) => data.items)))}}
                              placeholder={props.t("CHOOSE_PERMISSION_FOR_THE_ROLE")}
                              onChange={(e:any) => validation.handleChange(e)}
                              defaultCss={""}
                            />
                      </div>
                    </div>
                  </div>

                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary">
                      {props.t("SAVE_MODIFICATION")}
                    </button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LoadingContainer>
      <DialogBoxComponent
        handleClose={() => closeDialogModal()}
        handleConfirm={() => dialog.onConfirm()}
        show={dialog.show}
        type={dialog.type}
      />
    </React.Fragment>
  )
};
export default withTranslation()(EditRole);