/**
 * Permet de connaitre le type d'un élément dans un table list.
 * @type {{CURRENCY: number, DATE: number, NUMBER: number, STRING: number,BOOLEAN:number}}
 */
export enum TABLE_FIELD_TYPES {
  NUMBER = 1,
  STRING = 2,
  CURRENCY = 3,
  DATE = 4,
  EXCERPT_STRING = 5,
  BOOLEAN,
  TIME
}


/**
 * Définition des type de boite de dialoge
 */
export const DIALOG_TYPE = {
  CONFIRM: 1,
  CONFIRM_DELETION: 2,
  CONFIRM_BULK_DELETION: 22,
  ALERT: 3,
  SESSION_EXPIRED: 4,
  SERVER_ERROR: 5,
  AUTH_REQUIRED: 6,
  SUCCESS: 7,

};

/**
 * Permet de connaitre le sexe d'un user
 *
 * @type {{MAN: number, WOMAN: number}}
 */
export const GENDER = {
  MAN: 1,
  WOMAN: 2
};

/**
 * Permet de connaitre le sexe d'un user
 *
 * @type {{MAN: number, WOMAN: number}}
 */
export const LANG = {
  FRENCH: 1,
  ENGLISH: 2
};
/**
 * Permet d'avoir les différents statut d'une requête http
 */
export const HTTP_RESPONSE = {
  HTTP_CONTINUE: 100,
  HTTP_SWITCHING_PROTOCOLS: 101,
  HTTP_PROCESSING: 102,            // RFC2518
  HTTP_EARLY_HINTS: 103,           // RFC8297
  HTTP_OK: 200,
  HTTP_CREATED: 201,
  HTTP_ACCEPTED: 202,
  HTTP_NON_AUTHORITATIVE_INFORMATION: 203,
  HTTP_NO_CONTENT: 204,
  HTTP_RESET_CONTENT: 205,
  HTTP_PARTIAL_CONTENT: 206,
  HTTP_MULTI_STATUS: 207,          // RFC4918
  HTTP_ALREADY_REPORTED: 208,      // RFC5842
  HTTP_IM_USED: 226,               // RFC3229
  HTTP_MULTIPLE_CHOICES: 300,
  HTTP_MOVED_PERMANENTLY: 301,
  HTTP_FOUND: 302,
  HTTP_SEE_OTHER: 303,
  HTTP_NOT_MODIFIED: 304,
  HTTP_USE_PROXY: 305,
  HTTP_RESERVED: 306,
  HTTP_TEMPORARY_REDIRECT: 307,
  HTTP_PERMANENTLY_REDIRECT: 308,  // RFC7238
  HTTP_BAD_REQUEST: 400,
  HTTP_UNAUTHORIZED: 401,
  HTTP_PAYMENT_REQUIRED: 402,
  HTTP_FORBIDDEN: 403,
  HTTP_NOT_FOUND: 404,
  HTTP_METHOD_NOT_ALLOWED: 405,
  HTTP_NOT_ACCEPTABLE: 406,
  HTTP_PROXY_AUTHENTICATION_REQUIRED: 407,
  HTTP_REQUEST_TIMEOUT: 408,
  HTTP_CONFLICT: 409,
  HTTP_GONE: 410,
  HTTP_LENGTH_REQUIRED: 411,
  HTTP_PRECONDITION_FAILED: 412,
  HTTP_REQUEST_ENTITY_TOO_LARGE: 413,
  HTTP_REQUEST_URI_TOO_LONG: 414,
  HTTP_UNSUPPORTED_MEDIA_TYPE: 415,
  HTTP_REQUESTED_RANGE_NOT_SATISFIABLE: 416,
  HTTP_EXPECTATION_FAILED: 417,
  HTTP_I_AM_A_TEAPOT: 418,                                               // RFC2324
  HTTP_MISDIRECTED_REQUEST: 421,                                         // RFC7540
  HTTP_UNPROCESSABLE_ENTITY: 422,                                        // RFC4918
  HTTP_LOCKED: 423,                                                      // RFC4918
  HTTP_FAILED_DEPENDENCY: 424,                                           // RFC4918
  HTTP_TOO_EARLY: 425,                                                   // RFC-ietf-httpbis-replay-04
  HTTP_UPGRADE_REQUIRED: 426,                                            // RFC2817
  HTTP_PRECONDITION_REQUIRED: 428,                                       // RFC6585
  HTTP_TOO_MANY_REQUESTS: 429,                                           // RFC6585
  HTTP_REQUEST_HEADER_FIELDS_TOO_LARGE: 431,                             // RFC6585
  HTTP_UNAVAILABLE_FOR_LEGAL_REASONS: 451,
  HTTP_INTERNAL_SERVER_ERROR: 500,
  HTTP_NOT_IMPLEMENTED: 501,
  HTTP_BAD_GATEWAY: 502,
  HTTP_SERVICE_UNAVAILABLE: 503,
  HTTP_GATEWAY_TIMEOUT: 504,
  HTTP_VERSION_NOT_SUPPORTED: 505,
  HTTP_VARIANT_ALSO_NEGOTIATES_EXPERIMENTAL: 506,                        // RFC2295
  HTTP_INSUFFICIENT_STORAGE: 507,                                        // RFC4918
  HTTP_LOOP_DETECTED: 508,                                               // RFC5842
  HTTP_NOT_EXTENDED: 510,                                                // RFC2774
  HTTP_NETWORK_AUTHENTICATION_REQUIRED: 511,                             // RFC6585
};
