import React, {Fragment} from "react";
import {Container, Row, Col, Card, CardBody, BreadcrumbItem} from "reactstrap"
import UserRepository from "@repository/user/UserRepository";
import {DIALOG_TYPE, HTTP_RESPONSE} from "@utils/constants";
import LoadingContainer from "@components/utilities/LoadingContainer";
import DialogBoxComponent from "@components/utilities/DialogBoxComponent";
import "../loader/loader.css";
import PaginationWrap from "../utilities/PaginationWrap";
import ActionsFormatter from "../utilities/ActionsFormatter";
import { withTranslation } from "react-i18next";
class UsersList extends React.Component<any, any> {
  manager: UserRepository;

  /**
   * @param props
   */
  constructor(props: any) {
    super(props);
    this.manager = new UserRepository(props.apiUrl);
    this.state = {
      items: [],
      errors: [],
      total: 0,
      entity: null, // element courant
      itemPerpage: 10,
      loading: true,
      loadingSearch: false,
      query: "",
      showConfirmDialog: false,
      pageSizeOptions: {
        totalSize: 0, // replace later with size(customers),
        custom: true,
        onPageChange: (page: number) => this.onPageChange(page),
        onSearchChange: this.handleSearchChange,
        page: 1,
        sizePerPage: 10,
        pageStartIndex: 1,
        sizePerPageList: 10,
        showTotal: true,
        onSizePerPageChange: {}
      },
      dialog: {
        show: false,
        onConfirm: () => {
        },
        handleClose: () => {
        },
        message: "",
        type: DIALOG_TYPE.ALERT
      }
    };
    this.onPageChange = this.onPageChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    this.findAll();
  }

  onPageChange(page: number) {
    this.setState(
      (state: any) => ({
        ...state,
        loading: true,
      }),
      () => {
        this.findAll(page);
      }
    );
  }

  handleDelete(e: any, elt: any) {
    e.preventDefault();
    this.setState(
      (state: any) => ({
        ...state,
        entity: elt,
        dialog: {
          ...state.dialog,
          onConfirm: () => this.performDelete(),
          handleClose: () => this.closeDialogModal(),
          type: DIALOG_TYPE.CONFIRM_DELETION
        }
      }),
      () => {
        this.toggleDialogModal();
      }
    );
  }

  handleSearchChange(query: string) {
  }

  findAll(page = 1) {
    this.manager.retrieve(this.state.itemPerpage, page, this.state.query).then(data => {
      this.setState(
        (state: any) => ({
          ...state,
          items: data.items,
          total: data.items.length,
          pageSizeOptions: {
            sizePerPage: this.state.itemPerpage,
            totalSize: 10,
            custom: true,
            onPageChange: (page: number) => this.onPageChange(page),
            onSearchChange: this.handleSearchChange
          },
        }),
        () => {
          this.setState((state: any) => ({...state, loading: false, loadingSearch: false}));
        }
      );
    })
  }

  toggleDialogModal = () => {
    this.setState((state: any) => ({
      ...state,
      loading: false,
      dialog: {...state.dialog, show: !state.show}
    }));
  }

  closeDialogModal = () => {
    this.setState((state: any) => ({
      ...state,
      loading: false,
      dialog: {...state.dialog, show: false}
    }));
  }

  performDelete = () => {
    if (this.state.entity && this.state.entity.id) {
      this.setState(
        (state: any) => ({
          ...state,
          loading: true,
          dialog: {
            ...state.dialog,
            show: false
          }
        }),
        () => {
          const idToRemove = this.state.entity.id;
          this.manager.remove(this.state.entity.id).then(data => {
            this.setState((state: any) => ({
              ...state,
              entity: null,
              items: state.items.filter((elt: any) => elt.id !== idToRemove)
            }));
            this.closeDialogModal();
          }).catch(response => {
            this.catchError(response);
          })
        }
      );
    }
  }

  catchError = (response: any) => {
    const dialog = {
      ...this.state.dialog,
      show: true,
      onConfirm: () => this.closeDialogModal(),
      handleClose: () => this.closeDialogModal(),
      type: DIALOG_TYPE.ALERT
    }
    switch (response.status) {
      case HTTP_RESPONSE.HTTP_BAD_REQUEST: {
        dialog.type = DIALOG_TYPE.ALERT;
        break;
      }
      case HTTP_RESPONSE.HTTP_NOT_FOUND: {
        dialog.type = DIALOG_TYPE.ALERT;
        break;
      }
      case HTTP_RESPONSE.HTTP_INTERNAL_SERVER_ERROR: {
        let message = '';
        if (response.data.message) {
          message = response.data.message;
        }
        dialog.type = DIALOG_TYPE.SERVER_ERROR;
        break;
      }
      default:
    }
    this.setState((state: any) => ({
      ...state,
      dialog: dialog,
    }));
  }

  /**
   *  Methode appelée quand la valeur d'un champ de formulaire change
   *
   * @param target
   */
  handleFormInputChange = ({target}: any) => {
    const {name, value} = target;
    this.setState(
      (state: any) => ({
        ...state,
        [name]: value,
        loadingSearch: true
      }), () => {
        this.findAll();
      }
    );
  };

  handleOnPagePerPageChange = (p: number) => {
    this.setState(
      (state: any) => ({
        ...state,
        itemPerpage: p,
        page: 1,
        loading: true,
        pageSizeOptions: {
          ...state.pageSizeOptions,
          sizePerPage: p,
          page: 1,
        }
      }),
      () => {
        this.setState(
          (state: any) => ({
            ...state,
            loading: true
          }), () => {
            this.findAll();
          }
        );
      }
    );
  }

  render() {
    return <Fragment>
      <Container fluid={true}>
        <LoadingContainer loading={this.state.loading}>
          <Card>
            <CardBody>
              <div className="mb-4">
                <Row>
                  <Col xs="12">
                    <div className="page-title-box justify-content-between">
                      <div className="">
                        <ol className="breadcrumb m-0">
                          <BreadcrumbItem>.</BreadcrumbItem>
                          <BreadcrumbItem>
                            {this.props.t("USERS")}
                          </BreadcrumbItem>
                        </ol>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-2">
                  {
                    this.props.showAdd && (
                      <Col md="8">
                        <this.props.Link className="btn btn-primary" to={this.props.addUrl}>{this.props.t("ADD_USER")}</this.props.Link>
                      </Col>
                    )
                  }
                  <Col md="4">
                    <div className="search-box me-2 mb-2 d-inline-block float-end">
                      <form className="search-box d-inline-block">
                        <div className="position-relative">
                          <label htmlFor="search-bar" className="search-label">
                            <span id="search-bar-label" className="sr-only">{this.props.t("SEARCH")}</span>
                            <input
                              id="search-bar"
                              type="text"
                              name="query"
                              className="form-control "
                              placeholder={this.props.t("SEARCH")}
                              onChange={(e) => this.handleFormInputChange(e)}
                              value={this.state.query}/>
                          </label>
                          <i className="bx bx-search-alt search-icon"/>
                        </div>
                      </form>
                    </div>
                  </Col>
                </Row>
              </div>
              <PaginationWrap
                page={this.state.page}
                paginationProps={this.state.pageSizeOptions}
                handleOnPagePerPageChange={(p: number) => this.handleOnPagePerPageChange(p)}
                itemPerpage={this.state.itemPerpage}
                onPageChange={(p: number) => this.onPageChange(p)}
                totalItems={this.state.total}
              />
              <LoadingContainer loading={this.state.loadingSearch}>
                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <div className="react-bootstrap-table">
                        <table
                          className="table table align-middle table-nowrap">
                          <thead className="thead-light">
                          <tr>
                            <th tabIndex={0}>{this.props.t("NAME")}</th>
                            <th tabIndex={0}>{this.props.t("FIRST_NAME")}</th>
                            { this.props.userCompanyName ? <th tabIndex={0}>{this.props.t("COMPANY_NAME")}</th> : ''}
                            <th tabIndex={0}>{this.props.t("INITIALS")}</th>
                            <th tabIndex={0}>{this.props.t("EMAIL")}</th>
                            <th tabIndex={0}>{this.props.t("USERNAME")}</th>
                            <th tabIndex={0}>{this.props.t("STATUS")}</th>
                            { this.props.useLangue ? <th tabIndex={0}>{this.props.t("LANGUAGE")}</th> : ''}
                            <th tabIndex={0}/>
                          </tr>
                          </thead>
                          <tbody>
                          {this.state.items.map((item: any, index: number) => <tr key={index}>
                            <td>{item.lastname}</td>
                            <td>{item.firstname}</td>
                            { this.props.userCompanyName ? <td>{item.companyName ? item.companyName : ""}</td> : ''}
                            <td>{item.initials}</td>
                            <td>{item.email}</td>
                            <td>{item.username}</td>
                            <td>{item.enabled == 1 ? <span className="badge bg-primary p-2">{this.props.t("ACTIVE")}</span> :
                              <span className="badge bg-danger p-2">{this.props.t("INACTIVE")}</span>}
                            </td>

                            { this.props.useLangue ? <td>{item.langue ? item.langue : ""}</td> : ''}
                            <td>
                              <ActionsFormatter
                                item={item}
                                onDelete={(event: any, elt: any) => this.handleDelete(event, elt)}
                                path={this.props.path}
                                Link={this.props.Link}
                                showEdit = {this.props.showEdit} showView = {false} showDelete = {this.props.showDelete}
                              />
                            </td>
                          </tr>)}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Col>
                </Row>
              </LoadingContainer>
              <PaginationWrap
                page={this.state.page}
                paginationProps={this.state.pageSizeOptions}
                handleOnPagePerPageChange={(p: number) => this.handleOnPagePerPageChange(p)}
                itemPerpage={this.state.itemPerpage}
                onPageChange={(p: number) => this.onPageChange(p)}
                totalItems={this.state.total}
              />
            </CardBody>
          </Card>
        </LoadingContainer>
      </Container>
      <DialogBoxComponent
        handleClose={() => this.closeDialogModal()}
        handleConfirm={() => this.state.dialog.onConfirm()}
        show={this.state.dialog.show}
        type={this.state.dialog.type}
      />
    </Fragment>;
  }
}

export default withTranslation()(UsersList)
