import React, {FunctionComponent, useEffect, useState} from "react";
import "../loader/loader.css";
import LoadingContainer from "@components/utilities/LoadingContainer";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Label,
  Input} from "reactstrap"
import ReferenceValueRepository from "@repository/ReferenceValueRepository";
import { withTranslation } from "react-i18next";

/**
 *
 * Modal utilisée dans toute l'application
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ViewReferenceValue: FunctionComponent<any> = (props: any): any => {
  const {referenceValueId = 0, apiUrl} = props;
  const [loading, setLoading] = useState(true);
  const manager = new ReferenceValueRepository(apiUrl);

  const[referenceValue, setReferenceValue] = useState(null)


  useEffect(() => {
    if (referenceValueId) {
      setLoading(true);
      manager.findIncludeAll(referenceValueId).then((item) => {
        setLoading(false);
        setReferenceValue(item);
      })
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <React.Fragment>
      <LoadingContainer className="mb-4" loading={loading}>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle>{props.t("REFERENCE_VALUES_DETAILS")}</CardTitle>
                <div>
                  <div className="row">
                    <div className="col-md-8 col-12">
                        <div className="mb-3">
                            <Label className="form-label">{props.t("CODE")} </Label>
                            <Input
                            className="form-control"
                            type="text"
                            disabled={true}
                            value={referenceValue ? referenceValue.code : ""}
                            />
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">{props.t("DESCRIPTION")} </Label>
                            <Input
                            className="form-control"
                            type="text"
                            disabled={true}
                            value={referenceValue ? referenceValue.description : ""}
                            />
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">{props.t("IS_ACTIVE")} </Label>
                            <Input
                              className="form-control"
                              type="checkbox"
                              disabled={true}
                              checked={referenceValue ? referenceValue.isActive==1 : false }
                              onChange={()=>{}}
                            />
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">{props.t("ASSOCIATED_REFERENCE")}</Label>
                            <Input
                            className="form-control"
                            type="text"
                            disabled={true}
                            value={( referenceValue && referenceValue.reference ) ? referenceValue.reference.name : ""}
                            />
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">{props.t("ASSOCIATED_COMPANY")} </Label>
                            <Input
                            className="form-control"
                            type="text"
                            disabled={true}
                            value={( referenceValue && referenceValue.company) ? referenceValue.company.name : ""}
                            />
                        </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LoadingContainer>
    </React.Fragment>
  )
};

export default withTranslation()(ViewReferenceValue)