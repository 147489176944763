import React, {Fragment, FunctionComponent, useEffect, useState} from "react";
import {DIALOG_TYPE, HTTP_RESPONSE} from "@utils/constants";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useParams} from "react-router-dom";
import LoadingContainer from "@components/utilities/LoadingContainer";
import {
  Row,
  Col,
  Form,
  Card,
  CardBody,
  Label,
  Input,
  FormFeedback, Alert
} from "reactstrap"
import {DialogInterface} from "@app/core/types/app.state";
import UserRepository from "@repository/user/UserRepository";
import CompanyRepository from "@repository/CompanyRepository";

import {RegisterUserProps} from "@app/core/types/generic.interface";
import "../loader/loader.css";
import { withTranslation } from "react-i18next";
import AutocompleteSelectType from "../form/core/type/AutocompleteSelectType";
/**
 *
 * Modal utilisée dans toute l'application
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const RegisterUser: FunctionComponent<RegisterUserProps> = (props: RegisterUserProps) => {
  const {apiUrl = "", apiCompanyUrl="", apiUserCompanyUrl="",onRegisterSuccess = {}, useInitials=true, useLangue=false, useCompanyName=false} = props;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [selectCompany, setSelectCompany] = useState(false);

  const [dialog, setDialog] = useState<DialogInterface>({
    show: false,
    onConfirm: () => {
    },
    handleClose: () => {
    },
    message: "",
    type: DIALOG_TYPE.ALERT
  });
  const [enabled, setEnabled] = useState(false);
  const manager = new UserRepository(apiUrl);
  const companyManager = new CompanyRepository(apiCompanyUrl, apiUserCompanyUrl);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: false,
    initialValues: {
      email: '',
      username: '',
      lastname: '',
      firstname: '',
      initials: '',
      companyName: '',
      langue: '',
      password: null,
      companyId: null
    },
    validationSchema: Yup.object({
      email: Yup.string().required(props.t("ENTER_YOUR_FIRSNAME")),
      lastname: Yup.string().required(props.t("ENTER_YOUR_NAME")),
      companyId: Yup.array().test({test:(val)=>{ return (val.length> 0)},message: "Champs requis"}),
    }),
    onSubmit: (values) => {
      setLoading(true);
      let companies = validation.values.companyId.map((c:any)=>{return c.id})

      // load used language

      let userLang = "";
      if(useLangue){
        userLang = "en";
        if(localStorage.getItem("I18N_LANGUAGE")){
          userLang = localStorage.getItem("I18N_LANGUAGE")
        }else{
          if(/^fr\b/.test(navigator.language)){
            userLang = "fr";
          }
        }
      }

      manager.register({...values, username: values.email, enabled: 0, companyId: companies, langue: userLang, companyName: values.companyId[0].name} as any).then((user) => {
        setLoading(false);
        setSuccess(true);
        localStorage.setItem("verificationEmail", values.email)
        onRegisterSuccess(user);
      }).catch(response => {
        if (response != null && (response.status == HTTP_RESPONSE.HTTP_NOT_ACCEPTABLE || response.status == HTTP_RESPONSE.HTTP_BAD_REQUEST || response.status == HTTP_RESPONSE.HTTP_UNAUTHORIZED)) {
          if (response.data && response.data.error && response.data.error.message)
            setError(response.data.error.message);
          else if (response.data.message) {
            setError(response.data.message);
          }
        }else{
          setError("Une erreur inattendue s'est produite");
        }
        setLoading(false);
      })
      //dispatch(registerUser(values));
    }
  });

  const params = useParams();
  const {id = 0} = params;

  useEffect(() => {

    companyManager.findAll().then((data)=>{
      if(data.totalCount>1){
        setSelectCompany(true)
      }else if(data.totalCount==1){
        validation.setValues({...validation.values, companyId: [data.items[0]], companyName: data.items[0].name})
        setSelectCompany(false)
      }else{
        alert("Aucune compagnie trouvée en BD")
      }
    })

    if (id) {
      manager.find(parseInt(id)).then((item) => {
        item.companyName = item.companyName ? item.companyName : ""
        item.langue = item.langue ? item.langue : ""
        setEnabled(item.enabled === 1);
        setLoading(false);
        let parsedItem = item as any
        validation.setValues({...parsedItem, companyId:null});
      })
    } else {
      setLoading(false);
    }
  }, []);

  /**
   * Gestion de la soumission du formulaire
   *
   * @param event
   * @returns {Promise<void>}
   */
  const handleSubmitForm = (event: any) => {
    event.preventDefault();
    validation.handleSubmit();
  };

  const catchError = (response: any) => {
    const currentDialog: DialogInterface = {
      show: true,
      onConfirm: () => closeDialogModal(),
      handleClose: () => closeDialogModal(),
      type: DIALOG_TYPE.ALERT
    };
    switch (response.status) {
      case HTTP_RESPONSE.HTTP_BAD_REQUEST: {
        currentDialog.type = DIALOG_TYPE.ALERT;
        break;
      }
      case HTTP_RESPONSE.HTTP_NOT_FOUND: {
        currentDialog.type = DIALOG_TYPE.ALERT;
        break;
      }
      case HTTP_RESPONSE.HTTP_INTERNAL_SERVER_ERROR: {
        currentDialog.type = DIALOG_TYPE.SERVER_ERROR;
        currentDialog.message = '';
        if (response.data.message) {
          currentDialog.message = response.data.message;
        }
        break;
      }
      default:
    }
    setDialog(currentDialog);
  };

  /**
   */
  const closeDialogModal = () => {
    setDialog({...dialog, show: false})
  };

  return (
    <React.Fragment>
      <LoadingContainer loading={loading} className="p-md-2">
        <Card className="overflow-hidden">
          <div className="bg-primary bg-soft">
            <Row>
              <Col className="col-7">
                <div className="text-primary p-4">
                  <h5 className="text-primary"> {props.t("NEW_ACCOUNT")}</h5>
                  <p>{props.t("CREATE_ACCOUNT_IN_MINUTES")}</p>
                </div>
              </Col>
            </Row>
          </div>
          {!success ? <Fragment>
            <CardBody className="pt-0">
              <div className="p-2">
                <Form
                  className="form-horizontal"
                  onSubmit={(e) => handleSubmitForm(e)}
                >
                  {error.length ? (
                    <Alert color="danger">
                      {error}
                    </Alert>
                  ) : null}

                  <div className="mb-3">
                    <Label className="form-label">{props.t("FIRST_NAME")}</Label>
                    <Input
                      id="firstname"
                      name="firstname"
                      className="form-control"
                      placeholder={props.t("FIRST_NAME")}
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.firstname || ""}
                    />
                  </div>

                  <div className="mb-3">
                    <Label className="form-label">{props.t("NAME")}</Label>
                    <Input
                      id="lastname"
                      name="lastname"
                      className="form-control"
                      placeholder={props.t("NAME")}
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.lastname || ""}
                      invalid={
                        validation.touched.lastname && validation.errors.lastname ? true : false
                      }
                    />
                    {validation.touched.lastname && validation.errors.lastname ? (
                      <FormFeedback
                        type="invalid">{validation.errors.lastname}</FormFeedback>
                    ) : null}
                  </div>

                  {
                    useCompanyName ?
                    (
                      <div className="mb-3">
                        <Label className="form-label">{props.t("NOM_COMPANY")}</Label>
                        <Input
                          id="companyName"
                          name="companyName"
                          className="form-control"
                          placeholder={props.t("NOM_COMPANY")}
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.companyName || ""}
                          invalid={
                            validation.touched.companyName && validation.errors.companyName ? true : false
                          }
                        />
                        {validation.touched.companyName && validation.errors.companyName ? (
                          <FormFeedback
                            type="invalid" className="invalid-feedback d-block">{validation.errors.companyName}</FormFeedback>
                        ) : null}
                      </div>
                    ) : ""
                  }  

                  {
                    useInitials ?
                    (
                      <div className="mb-3">
                        <Label className="form-label">{props.t("INITIALS")}</Label>
                        <Input
                          id="initials"
                          name="initials"
                          className="form-control"
                          placeholder={props.t("INITIALS")}
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.initials || ""}
                          invalid={
                            validation.touched.initials && validation.errors.initials ? true : false
                          }
                        />
                        {validation.touched.initials && validation.errors.initials ? (
                          <FormFeedback
                            type="invalid">{validation.errors.initials}</FormFeedback>
                        ) : null}
                      </div>
                    ) : ""
                  }

                  <div className="mb-3">
                    <Label className="form-label">{props.t("EMAIL")}</Label>
                    <Input
                      id="email"
                      name="email"
                      className="form-control"
                      placeholder={props.t("EMAIL")}
                      type="email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ""}
                      invalid={
                        validation.touched.email && validation.errors.email ? true : false
                      }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback
                        type="invalid">{validation.errors.email}</FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <Label
                      className="form-label">{props.t("PASSWORD")}</Label>
                    <Input
                      name="password"
                      type="password"
                      placeholder={props.t("PASSWORD")}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.password || ""}
                      invalid={
                        validation.touched.password && validation.errors.password ? true : false
                      }
                    />
                    {validation.touched.password && validation.errors.password ? (
                      <FormFeedback
                        type="invalid">{ '<p>' + validation.errors.password + '</p>'}</FormFeedback>
                    ) : null}
                  </div>

                  {
                    selectCompany ?
                    (

                      <div className="mb-3">
                        <Label className="form-label">Compagnie(s) associée(s)</Label>
                        <AutocompleteSelectType
                            id={"companyId"}
                            name={"companyId"}
                            multiple={true}
                            choiceAttr={"name"}
                            value={validation.values.companyId || []}
                            remoteChoices={(inputValue:any) => {return new Promise(resolve => resolve(companyManager.findAll( 1,10, inputValue).then((data) => data.items)))}}
                            placeholder={"Choisir le(s) compagnie(s) à associer ..."}
                            onChange={(e:any) => validation.handleChange(e)}
                            defaultCss={""}
                          />
                          {validation.touched.companyId && ( validation.errors.companyId || validation.values.companyId == null || validation.values.companyId.length==0) ? (
                            <FormFeedback className="invalid-feedback d-block" type="invalid">{ '<p> Champ requis </p>'}</FormFeedback>
                            ) : null
                          }
                      </div>
                    ) : ""
                  }   
                  <div className="mt-4">
                    <button
                      className="btn btn-primary btn-block "
                      type="submit"
                    >
                      {props.t("CREATE_ACCOUNT")}
                    </button>
                  </div>
                </Form>
              </div>
            </CardBody>
          </Fragment> : <Fragment>
            <div className="p-5">
              <Alert color="success">
                {props.t("GREAT_ACCOUNT_CREATED")}
              </Alert>
            </div>
          </Fragment>}
        </Card>
      </LoadingContainer>
    </React.Fragment>
  )
};
export default withTranslation()(RegisterUser);
