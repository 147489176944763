import React, {FunctionComponent, useEffect, useState} from "react";
import {HTTP_RESPONSE} from "@utils/constants";
import axios from "axios";
import {ParameterProps} from "@app/core/types/generic.interface";

/**
 *
 * Affiche le lien du copyright
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const CopyrightValue: FunctionComponent<ParameterProps> = (props: ParameterProps) => {
  const {apiUrl = "", code = "DEFAULT"} = props;
  const [parameter, setParameter] = useState<any>({
    logoId: "",
    copyright: "",
  });
  useEffect(()=>{
    axios
      .get(apiUrl + '/code/' + code)
      .then(response => {
        setParameter(response.data);
      }).catch((e) => {
      if (e.response) {
        throw e.response;
      } else {
        throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
      }
    });
  },[])

  return (
    <React.Fragment>
      <p className="mb-0">
        {parameter != null ? parameter.copyright: "Point Pub Media"}
      </p>
    </React.Fragment>
  )
};
export default CopyrightValue;
