import React, {FunctionComponent, useEffect, useState} from "react";
import {DIALOG_TYPE, HTTP_RESPONSE} from "@utils/constants";
import { withTranslation } from "react-i18next";
import {useFormik} from "formik";
import "../loader/loader.css";
import * as Yup from "yup";
import LoadingContainer from "@components/utilities/LoadingContainer";
import {
  Row,
  Col,
  Form,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  FormFeedback
} from "reactstrap"
import DialogBoxComponent from "../utilities/DialogBoxComponent";
import AutocompleteSelectType from "@app/helpers/Form/AutocompleteSelectType";
import Autocomplete from "@app/helpers/select.autocomplete";
import ReferenceLabelRepository from "@app/repository/ReferenceLabelRepository";
import ReferenceValueRepository from "@app/repository/ReferenceValueRepository";

/**
 *
 * Modal utilisée dans toute l'application
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const EditReferenceLabel: FunctionComponent<any> = (props: any): any => {
  const {referenceLabelId, referenceValueId = 0, onEditSuccess = {}, apiUrl, apiLangueUrl, apiReferenceValueUrl} = props;
  const [loading, setLoading] = useState(true);
  const [loadingReferenceValue, setLoadingReferenceValue] = useState(true)

  const [dialog, setDialog] = useState({
    show: false,
    onConfirm: () => {
    },
    handleClose: () => {
    },
    message: "",
    type: DIALOG_TYPE.ALERT
  });

  const manager = new ReferenceLabelRepository(apiUrl);
  const referenceValueManager = new ReferenceValueRepository(apiReferenceValueUrl)


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      label : '',
      referenceValueId: null,
      langueId: null
    },
    validationSchema: Yup.object({
      label: Yup.string().required("Veuillez entrer le label"),
      langueId: Yup.object().required(props.t("REQUIRED")),
    }),
    onSubmit: (values) => {
      setLoading(true)
      let tmp = JSON.parse(JSON.stringify(values))

      if(tmp.referenceValueId){
        tmp.referenceValueId = tmp.referenceValueId.id
      }else{
        delete tmp["referenceValueId"]
      }

      if(tmp.langueId){
        tmp.langueId = tmp.langueId.id
      }else{
        delete tmp["langueId"]
      }

      manager.flush({...tmp }).then(async (response: any) => { 
        setLoading(false);
        if (typeof onEditSuccess == 'function') {
          onEditSuccess(response);
        }
      }).catch((response: any) => {
        catchError(response)
      })
  
    }
  });

  useEffect(() => {
    if (referenceLabelId) {
      setLoading(true);
      manager.findIncludeAll(referenceLabelId).then((item:any) => {
        if(item.langueId){
          item.langueId = item.langue
        }
        delete item["langue"]

        if(item.referenceValueId){
          item.referenceValueId = item.referenceValue
        }
        delete item["referenceValue"]

        validation.setValues(item);

        setLoading(false);
        setLoadingReferenceValue(false)
      }).catch((error:any)=>{
        catchError(error);
      })
    } else {
      setLoading(false);
      referenceValueManager.find(referenceValueId).then( (item:any) =>{
        let values = validation.values
        validation.setValues( {...values, referenceValueId:item})
        setLoadingReferenceValue(false)
      })
    }
  }, []);

  /**
   * Gestion de la soumission du formulaire
   *
   * @param event
   * @returns {Promise<void>}
   */
  const handleSubmitForm = (event: any) => {
    event.preventDefault();
    validation.handleSubmit();
  };

  const catchError = (response: any) => {
    const currentDialog = {
      show: true,
      onConfirm: () => closeDialogModal(),
      handleClose: () => closeDialogModal(),
      type: DIALOG_TYPE.ALERT,
      message: ""
    };
    switch (response.status) {
      case HTTP_RESPONSE.HTTP_BAD_REQUEST: {
        currentDialog.type = DIALOG_TYPE.ALERT;
        break;
      }
      case HTTP_RESPONSE.HTTP_NOT_FOUND: {
        currentDialog.type = DIALOG_TYPE.ALERT;
        break;
      }
      case HTTP_RESPONSE.HTTP_INTERNAL_SERVER_ERROR: {
        currentDialog.type = DIALOG_TYPE.SERVER_ERROR;
        currentDialog.message = '';
        if (response.data.message) {
          currentDialog.message = response.data.message;
        }
        break;
      }
      case HTTP_RESPONSE.HTTP_UNPROCESSABLE_ENTITY: {
        currentDialog.type = DIALOG_TYPE.SERVER_ERROR;
        if(response.data){
          if( response.data.error.code=="ER_DUP_ENTRY"){
              currentDialog.message = "Un label de référence avec le même label existe déjà";
          }else{
              currentDialog.message = "Veuillez vérifiez les champs du formulaire";
          }
        }else{
            currentDialog.message = response.message
        }
        break;
      }
      default:
    }
    setDialog(currentDialog);
  };

  /**
   */
  const closeDialogModal = () => {
    setDialog({...dialog, show: false})
  };

  return (
    <React.Fragment>
      <LoadingContainer className="mb-4" loading={loading}>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle>{props.t("REFERENCE_LABELS_DETAILS")}</CardTitle>
                <CardSubtitle className="mb-3">
                  {props.t("UPDATE_REFERENCE_LABEL_DESCRIPTION")}
                </CardSubtitle>
                <Form onSubmit={(e) => handleSubmitForm(e)}>
                  <div className="row">
                    <div className="col-md-8 col-12">
                          <div className="mb-3">
                            <Label className="form-label">{props.t("ASSOCIATED_REFERENCE_VALUE")}</Label>
                            {
                              loadingReferenceValue  ?
                              ( <p>{props.t("LOADING")}</p> ) :
                              <AutocompleteSelectType
                                  id={"referenceValueId"}
                                  name={"referenceValueId"}
                                  multiple={false}
                                  isDisabled={true}
                                  choiceAttr={"code"}
                                  value={validation.values.referenceValueId}
                                  defaultCss={""}
                                />
                            }
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">
                              {props.t("ASSOCIATED_LANGUE")}
                            </Label>
                            <AutocompleteSelectType
                                id={"langueId"}
                                name={"langueId"}
                                multiple={false}
                                className="input-form"
                                choiceAttr="code"
                                value={validation.values.langueId || ""}
                                remoteChoices={(inputValue:any) => Autocomplete.referenceLangues(apiLangueUrl, inputValue)}
                                placeholder={props.t("SELECT_LANGUE")}
                                onChange={(e:any) => validation.handleChange(e)}
                                defaultCss={""}
                                error={
                                  validation.touched.langueId && validation.errors.langueId ? props.t("REQUIRED") : false
                                }
                              />
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">{props.t("LABEL")} <span className="text-danger">*</span> </Label>
                            <Input
                            id="label"
                            name="label"
                            className="form-control"
                            placeholder={props.t("LABEL_REQUIRED")}
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.label || ""}
                            invalid={
                              validation.touched.label && validation.errors.label ? true : false
                            }
                            />
                            {validation.touched.label && validation.errors.label ? (
                            <FormFeedback
                                type="invalid" className="invalid-feedback d-block">{validation.errors.label}</FormFeedback>
                            ) : null}
                        </div>
                    </div>
                  </div>

                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary">
                      {props.t("SAVE_MODIFICATIONS")}
                    </button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LoadingContainer>
      <DialogBoxComponent
        handleClose={() => closeDialogModal()}
        handleConfirm={() => dialog.onConfirm()}
        show={dialog.show}
        type={dialog.type}
        message={dialog.message}
      />
    </React.Fragment>
  )
};
export default withTranslation()(EditReferenceLabel)