import React, {FunctionComponent, useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {
  Form,
  Label,
  Input,
  FormFeedback, Alert
} from "reactstrap"
import {LoginProps} from "@app/core/types/generic.interface";
import UserRepository from "@repository/user/UserRepository";
import {HTTP_RESPONSE} from "@utils/constants";
import UserProvier from "@services/UserProvier";
import LoadingContainer from "@components/utilities/LoadingContainer";
import "../loader/loader.css";
import { withTranslation } from "react-i18next";
import CompanyRepository from "@app/repository/CompanyRepository";
import AutocompleteSelectType from "../form/core/type/AutocompleteSelectType";
/**
 *
 * Modal utilisée dans toute l'application
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const LoginUser: FunctionComponent<LoginProps> = (props: LoginProps) => {
  const {apiUrl = "", onLoginSuccess = {}, apiCompanyUrl = ""} = props;
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const manager = new UserRepository(apiUrl);

  const [selectCompany, setSelectCompany] = useState(false);

  const companyManager = new CompanyRepository(apiCompanyUrl,"")
  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
      companyId: null
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Entrer votre pseudo ou mot de passe"),
      password: Yup.string().required("Entrer votre mot de passe"),
      companyId: Yup.object().required("Champ requis")
    }),
    onSubmit: (values) => {
      setLoading(true);
      if(values.companyId){
        values.companyId = values.companyId.id
      }else{
        delete values["companyId"]
      }
      manager.login({username:values.email, email:values.email,password: values.password, companyId: values.companyId}).then((user) => {
        localStorage.setItem("authUser", JSON.stringify(user));
        UserProvier.setToken();
        setLoading(false);
        onLoginSuccess(user);
      }).catch(response => {
        setLoading(false);
        if (response != null && (response.status == HTTP_RESPONSE.HTTP_NOT_ACCEPTABLE || response.status == HTTP_RESPONSE.HTTP_BAD_REQUEST || response.status == HTTP_RESPONSE.HTTP_UNAUTHORIZED)) {
          setError(response.data.error.message);
        }
      })
    }
  });

  useEffect(()=>{
    if(apiCompanyUrl){
      companyManager.findAll().then((data)=>{
        if(data.totalCount>1){
          setSelectCompany(true)
        }else if(data.totalCount==1){
          validation.setValues({...validation.values, companyId: data.items[0]})
          setSelectCompany(false)
        }else{
          alert("Aucune compagnie trouvée en BD")
        }
      })
    }
  },[])

  return (
    <React.Fragment>
      <LoadingContainer loading={loading}>
        <Form className="form-horizontal"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
        >
          {error ? <Alert color="danger">{error}</Alert> : null}


          <div className="mb-3">
            <Label className="form-label">{props.t("EMAIL_OR_NICKNAME")}</Label>
            <Input
              name="email"
              className="form-control"
              placeholder=""
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.email || ""}
              invalid={
                validation.touched.email && validation.errors.email ? true : false
              }
            />
            {validation.touched.email && validation.errors.email ? (
              <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">{props.t("PASSWORD")}</Label>
            <Input
              name="password"
              value={validation.values.password || ""}
              type="password"
              placeholder=""
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              invalid={
                validation.touched.password && validation.errors.password ? true : false
              }
            />
            {validation.touched.password && validation.errors.password ? (
              <FormFeedback
                type="invalid">{validation.errors.password}</FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label className="form-label">Compagnie</Label>
            <AutocompleteSelectType
                id={"companyId"}
                name={"companyId"}
                multiple={false}
                choiceAttr={"name"}
                disabled={!selectCompany}
                value={validation.values.companyId || []}
                remoteChoices={(inputValue:any) => {return new Promise(resolve => resolve(companyManager.findAll( 1,10, inputValue).then((data) => data.items)))}}
                placeholder={"Choisir une compagnie"}
                onChange={(e:any) => validation.handleChange(e)}
                defaultCss={""}
              />
              {validation.touched.companyId && ( validation.errors.companyId || validation.values.companyId == null || validation.values.companyId.length==0) ? (
                <FormFeedback className="invalid-feedback d-block" type="invalid">{ 'Champ requis'}</FormFeedback>
                ) : null
              }
          </div>
          <div className="form-check">
            <Input
              type="checkbox"
              className="form-check-input"
              id="auth-remember-check"
            />
            <label
              className="form-check-label"
              htmlFor="auth-remember-check"
            >
              {props.t("REMEMBER_ME")}
            </label>
          </div>

          <div className="mt-3 d-grid">
            <button
              className="btn btn-primary btn-block "
              type="submit">
              {props.t("CONNECT")}
            </button>
          </div>
        </Form>
      </LoadingContainer>
    </React.Fragment>
  )
};
export default  withTranslation()(LoginUser);
