import React from "react";

const ActionsFormatter = (props :any) => {
    const {
        item,
        onDelete = {},
        path = "",
        Link,
        showEdit = true, showView = true, showDelete = true
    } = props;
    return (
        <div className="bx-pull-right file-system">
            {
                showView ?  <Link className="btn" to={ path + '/' + item.id + '/detail'}> <i className="bx bxs-show action-icon"/> </Link> 
                : ""
            }
            {
                showEdit ?  <Link className="btn" to={ path + '/' + item.id + '/edit'}> <i className="bx bxs-edit action-icon"/> </Link> 
                : ""
            }
            {
                showDelete ? <Link style={{marginLeft: "15px"}} className="btn" to="#" onClick={((event: any) => onDelete(event, item))}> <i className="bx bx-trash action-icon"/> </Link>
                : ""
            }
        </div>
    )
}

export default ActionsFormatter