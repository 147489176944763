import React, {FunctionComponent, useEffect, useState} from "react";
import {DIALOG_TYPE, HTTP_RESPONSE} from "@utils/constants";
import { withTranslation } from "react-i18next";
import {useFormik} from "formik";
import "../loader/loader.css";
import * as Yup from "yup";
import LoadingContainer from "@components/utilities/LoadingContainer";
import {
  Row,
  Col,
  Form,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  FormFeedback
} from "reactstrap"
import DialogBoxComponent from "../utilities/DialogBoxComponent";
import AutocompleteSelectType from "@app/helpers/Form/AutocompleteSelectType";
import Autocomplete from "@app/helpers/select.autocomplete";
import ReferenceValueRepository from "@app/repository/ReferenceValueRepository";
import ReferenceDropDown from "../dropdowns/ReferenceDropDown";
import ReferenceRepository from "@app/repository/ReferenceRepository";

/**
 *
 * Modal utilisée dans toute l'application
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const EditReferenceValue: FunctionComponent<any> = (props: any): any => {
  const {referenceValueId = 0, referenceId=0, onEditSuccess = {}, apiUrl , apiCompanyUrl, apiReferenceUrl} = props;
  const [loading, setLoading] = useState(true);
  const [loadingReference, setLoadingReference] = useState(true)
  const [dialog, setDialog] = useState({
    show: false,
    onConfirm: () => {
    },
    handleClose: () => {
    },
    message: "",
    type: DIALOG_TYPE.ALERT
  });

  const manager = new ReferenceValueRepository(apiUrl);

  const referenceManager = new ReferenceRepository(apiReferenceUrl)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      code : '',
      description: '',
      isActive: true,
      referenceId: null,
      companyId: null
    },
    validationSchema: Yup.object({
      code: Yup.string().required(props.t("CODE_REQUIRED")),
      companyId: Yup.object().required(props.t("REQUIRED")),
    }),
    onSubmit: (values) => {
      setLoading(true)
      let tmp = JSON.parse(JSON.stringify(values))
      
      if(tmp.description){
      }else{
        tmp.description = ""
      }

      tmp.isActive = tmp.isActive ? 1 : 0

      if(tmp.referenceId){
        tmp.referenceId = tmp.referenceId.id
      }else{
        delete tmp["referenceId"]
      }

      if(tmp.companyId){
        tmp.companyId = tmp.companyId.id
      }else{
        delete tmp["companyId"]
      }

      manager.flush({...tmp }).then(async (response: any) => { 
        setLoading(false);
        if (typeof onEditSuccess == 'function') {
          onEditSuccess(response);
        }
      }).catch((response: any) => {
        catchError(response)
      })
  
    }
  });

  useEffect(() => {
    if (referenceValueId) {
      setLoading(true);
      manager.findIncludeAll(referenceValueId).then((item:any) => {

        if(item.referenceId){
          item.referenceId = item.reference
        }
        delete item["reference"]

        if(item.companyId){
          item.companyId = item.company
        }
        delete item["company"]

        item.isActive = item.isActive==1

        validation.setValues(item);

        setLoading(false);
        setLoadingReference(false)
      }).catch((error:any)=>{
        catchError(error);
      })
    } else {
      setLoading(false);
      referenceManager.find(referenceId).then( (item:any) =>{
        let values = validation.values
        validation.setValues( {...values, referenceId:item})
        setLoadingReference(false)
      })
    }
  }, []);

  /**
   * Gestion de la soumission du formulaire
   *
   * @param event
   * @returns {Promise<void>}
   */
  const handleSubmitForm = (event: any) => {
    event.preventDefault();
    validation.handleSubmit();
  };

  const catchError = (response: any) => {
    const currentDialog = {
      show: true,
      onConfirm: () => closeDialogModal(),
      handleClose: () => closeDialogModal(),
      type: DIALOG_TYPE.ALERT,
      message: ""
    };
    switch (response.status) {
      case HTTP_RESPONSE.HTTP_BAD_REQUEST: {
        currentDialog.type = DIALOG_TYPE.ALERT;
        break;
      }
      case HTTP_RESPONSE.HTTP_NOT_FOUND: {
        currentDialog.type = DIALOG_TYPE.ALERT;
        break;
      }
      case HTTP_RESPONSE.HTTP_INTERNAL_SERVER_ERROR: {
        currentDialog.type = DIALOG_TYPE.SERVER_ERROR;
        currentDialog.message = '';
        if (response.data.message) {
          currentDialog.message = response.data.message;
        }
        break;
      }
      case HTTP_RESPONSE.HTTP_UNPROCESSABLE_ENTITY: {
        currentDialog.type = DIALOG_TYPE.SERVER_ERROR;
        if(response.data){
          if( response.data.error.code=="ER_DUP_ENTRY"){
              currentDialog.message = "Une valeur de référence avec le même code existe déjà";
          }else{
              currentDialog.message = "Veuillez vérifiez les champs du formulaire";
          }
        }else{
            currentDialog.message = response.message
        }
        break;
      }
      default:
    }
    setDialog(currentDialog);
  };

  /**
   */
  const closeDialogModal = () => {
    setDialog({...dialog, show: false})
  };

  return (
    <React.Fragment>
      <LoadingContainer className="mb-4" loading={loading}>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle>{props.t("UPDATE_REFERENCE_VALUE")}</CardTitle>
                <CardSubtitle className="mb-3">
                {props.t("UPDATE_REFERENCE_VALUE_DESCRIPTION")}
                </CardSubtitle>
                <Form onSubmit={(e) => handleSubmitForm(e)}>
                  <div className="row">
                    <div className="col-md-8 col-12">

                    <div className="mb-3">
                        <Label
                            className="form-label">{props.t("ASSOCIATED_REFERENCE")}</Label>
                          {
                            loadingReference  ?
                            ( <p>Loading ...</p> ) :
                            <AutocompleteSelectType
                                id={"referenceId"}
                                name={"referenceId"}
                                multiple={false}
                                isDisabled={true}
                                choiceAttr={"name"}
                                value={validation.values.referenceId}
                                defaultCss={""}
                              />
                          }
                      </div>
                      <div className="mb-3">
                        <Label
                            className="form-label">{props.t("ASSOCIATED_COMPANY")}</Label>
                        <AutocompleteSelectType
                            id={"companyId"}
                            name={"companyId"}
                            multiple={false}
                            isDisabled={referenceValueId!=0}
                            choiceAttr={"name"}
                            value={validation.values.companyId || []}
                            remoteChoices={(inputValue:any) => Autocomplete.referenceCompanies(apiCompanyUrl, inputValue)}
                            placeholder={"Choisir une compagnie ..."}
                            onChange={(e:any) => validation.handleChange(e)}
                            defaultCss={""}
                            error={
                              (validation.touched.companyId && validation.errors.companyId) ? props.t("REQUIRED") : false
                            }
                          />
                      </div>
                      <div className="mb-3">
                          <Label className="form-label">{props.t("CODE")} <span className="text-danger">*</span> </Label>
                          <Input
                          id="code"
                          name="code"
                          className="form-control"
                          placeholder={props.t("ENTER_CODE")}
                          type="text"
                          disabled={referenceValueId!=0}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.code || ""}
                          invalid={
                            validation.touched.code && validation.errors.code ? true : false
                          }
                          />
                          {validation.touched.code && validation.errors.code ? (
                          <FormFeedback
                              type="invalid" className="invalid-feedback d-block">{validation.errors.code}</FormFeedback>
                          ) : null}
                      </div>
                      <div className="mb-3">
                          <Label className="form-label">{props.t("DESCRIPTION")}</Label>
                          <Input
                          id="description"
                          name="description"
                          className="form-control"
                          placeholder={props.t("ENTER_DESCRIPTION")}
                          type="textarea"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.description || ""}
                          />
                      </div>
                      <div className="d-flex gap-3 mb-3">
                        <Label className="form-label">{props.t("IS_ACTIVE")}</Label>
                        <Input
                            id="isActive"
                            key={validation.values.isActive?1:0}
                            name="isActive"
                            className="form-control"
                            type="checkbox"
                            checked={validation.values.isActive}
                            onChange={()=>{}}
                            onClick={(e)=>{
                                    let tmp = validation.values
                                    validation.setValues({...tmp, isActive:!tmp.isActive})
                                }
                            }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary">
                      {props.t("SAVE_MODIFICATIONS")}
                    </button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LoadingContainer>
      <DialogBoxComponent
        handleClose={() => closeDialogModal()}
        handleConfirm={() => dialog.onConfirm()}
        show={dialog.show}
        type={dialog.type}
        message={dialog.message}
      />
    </React.Fragment>
  )
};
export default withTranslation()(EditReferenceValue)