/**
 * UserProvider
 * Enable to manage user session and acces authorization.
 *
 * @since      2.0.0
 * @package    App
 * @subpackage App\Core\Authorization
 */
import axios from "axios";

const UserProvier = {
    /**
     * Récupère l'utilisateur courant ou renvoit null si non existant
     */
    get: () => {
        return JSON.parse(localStorage.getItem("authUser"));
    },
    roles: () => {
        const user = UserProvier.get();
        if (user != null)
            return [...user.permissions, "ROLE_USER"];
        return [];
    },
    token: () => {
        const user = UserProvier.get();
        if (user != null)
            return user.token;

        return null;
    },
    setToken: () => {
        const token = UserProvier.token();
        if (token != null) {
            axios.defaults.headers['Authorization'] = "Bearer " + token;
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}` 
        } else {
            delete axios.defaults.headers['Authorization'];
        }
    },
}
export default UserProvier;
