import React, {FunctionComponent, useEffect, useState} from "react";
import {HTTP_RESPONSE} from "@utils/constants";
import axios from "axios";
import {ParameterProps} from "@app/core/types/generic.interface";

/**
 *
 * Affiche le lien du copyright
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const LogoValue: FunctionComponent<ParameterProps> = (props: ParameterProps) => {
  const {apiUrl = "", code = "DEFAULT", serverBaseUrl = ""} = props;
  const [parameter, setParameter] = useState<any>({
    logoId: "",
    copyright: "",
  });
  useEffect(()=>{
    axios
      .get(apiUrl + '/code/' + code)
      .then(response => {
        setParameter(response.data);
      }).catch((e) => {
      if (e.response) {
        throw e.response;
      } else {
        throw {...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND};
      }
    });
  },[]);

  /**
   * Renvoit le chemin vers l'image
   */
  const getFileUrl = (fileId: any) => {
    return serverBaseUrl + "uploads/files/" + fileId
  }

  return (
    <React.Fragment>
      {parameter != null &&
      <img
        src={getFileUrl(parameter.logoId)}
        alt=""
        height="40"
        className="auth-logo-dark"
      />}
    </React.Fragment>
  )
};
export default LogoValue;
