import React, {Fragment, FunctionComponent, useEffect, useState} from 'react';

interface Props {
  itemsPerPage:number,
  totalItems:number,
  activePage:number,
  onPageChange:any,
}

/**
 *
 * @returns {*}
 * @constructor
 */
const PaginationComponent: FunctionComponent<Props> = (props:Props) => {
    const {itemsPerPage, totalItems, onPageChange, activePage = 1} = props;
    const [currentPage, setCurrentPage] = useState(activePage);
    const pagesCount: number = parseInt(String(Math.ceil(totalItems / itemsPerPage)));
    const enabledPagination = itemsPerPage <= totalItems && totalItems > 0;
    const pages = [];
    useEffect(() => {
        setCurrentPage(activePage);
    }, []);
    for (let i = 1; i <= pagesCount; i++)
        pages.push(i);
    const handlePageChange = (page: number) => {
        let p = 1;
        if (page < 1) {
            p = 1;
        } else if (page > pagesCount) {
            p = pagesCount;
        } else {
            p = page;
        }
        if (p !== currentPage) {
            setCurrentPage(p);
            onPageChange(p);
        }
    };
    return (
        <Fragment>
            {enabledPagination &&
            <div className={"display-inline d-flex flex-row-reverse"}>
                <nav className="ml-auto">
                    <ul className="pagination mb-0">
                        <li className={"page-item " + (currentPage <= 1 && "disabled")}
                            onClick={() => handlePageChange(currentPage - 1)}>
                            <button className="page-link">&laquo;</button>
                        </li>
                        {pages.map(page => {
                            return (
                                <li className={"page-item " + (currentPage === page && "active")} key={page}
                                    onClick={() => handlePageChange(page)}>
                                    <button className="page-link">{page}</button>
                                </li>
                            );
                        })}
                        <li className={"page-item " + (currentPage >= pagesCount && "disabled")}
                            onClick={() => handlePageChange(currentPage + 1)}>
                            <button className="page-link">&raquo;</button>
                        </li>
                    </ul>
                </nav>
            </div>
            }
        </Fragment>
    );
};

export default PaginationComponent;
