import React, {FunctionComponent, useEffect, useState} from "react";
import {DIALOG_TYPE, HTTP_RESPONSE} from "@utils/constants";
import {useFormik} from "formik";
import "../loader/loader.css";
import * as Yup from "yup";
import LoadingContainer from "@components/utilities/LoadingContainer";
import {
  Row,
  Col,
  Form,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  FormFeedback, Alert
} from "reactstrap"
import {DialogInterface} from "@app/core/types/app.state";
import UserRepository from "@repository/user/UserRepository";
import Access from "@entity/user/Access";
import {EditUserProps} from "@app/core/types/generic.interface";
import { withTranslation } from "react-i18next";
/**
 *
 * Modal utilisée dans toute l'application
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const EditUser: FunctionComponent<EditUserProps> = (props: EditUserProps) => {
  const {id = 0, onEditSuccess = {}, apiUrl = ""} = props;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [dialog, setDialog] = useState<DialogInterface>({
    show: false,
    onConfirm: () => {
    },
    handleClose: () => {
    },
    message: "",
    type: DIALOG_TYPE.ALERT
  });
  const [enabled, setEnabled] = useState(false);
  const manager = new UserRepository(apiUrl);
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: '',
      username: '',
      lastname: '',
      firstname: '',
      initials: '',
      enabled: 0,
      password: null,
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Veuillez entrer votre email"),
      username: Yup.string().required("Veuille entrer votre identifiant"),
      lastname: Yup.string().required("Veuille entrer votre nom"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      const user = new Access();
      let tmp = values;
      if(tmp.password){}
      else{
        delete tmp.password;
      }
      manager.flush({...user, ...tmp, username: values.email, enabled: enabled ? 1 : 0}).then((user) => {
        setLoading(false);
        if (typeof onEditSuccess == 'function') {
          onEditSuccess(user);
        }
      }).catch(response => {
        const {data = null} = response;
        if (data != null && data.code && data.code == HTTP_RESPONSE.HTTP_NOT_ACCEPTABLE || data.code == HTTP_RESPONSE.HTTP_BAD_REQUEST) {
          setError(data.message);
        }
        setLoading(false);
      })
    }
  });

  useEffect(() => {
    if (id) {
      setLoading(true);
      manager.find(id).then((item) => {
        setEnabled(item.enabled === 1);
        setLoading(false);
        validation.setValues(item);
      })
    } else {
      setLoading(false);
    }
  }, []);
  /**
   *  Methode appelée quand la valeur d'un champ de formulaire change
   *
   * @param target
   */
  const toogleEnable = ({target}: any) => {
    setEnabled(!enabled);
  };

  /**
   * Gestion de la soumission du formulaire
   *
   * @param event
   * @returns {Promise<void>}
   */
  const handleSubmitForm = (event: any) => {
    event.preventDefault();
    validation.handleSubmit();
  };

  const catchError = (response: any) => {
    const currentDialog: DialogInterface = {
      show: true,
      onConfirm: () => closeDialogModal(),
      handleClose: () => closeDialogModal(),
      type: DIALOG_TYPE.ALERT
    };
    switch (response.status) {
      case HTTP_RESPONSE.HTTP_BAD_REQUEST: {
        currentDialog.type = DIALOG_TYPE.ALERT;
        break;
      }
      case HTTP_RESPONSE.HTTP_NOT_FOUND: {
        currentDialog.type = DIALOG_TYPE.ALERT;
        break;
      }
      case HTTP_RESPONSE.HTTP_INTERNAL_SERVER_ERROR: {
        currentDialog.type = DIALOG_TYPE.SERVER_ERROR;
        currentDialog.message = '';
        if (response.data.message) {
          currentDialog.message = response.data.message;
        }
        break;
      }
      default:
    }
    setDialog(currentDialog);
  };

  /**
   */
  const closeDialogModal = () => {
    setDialog({...dialog, show: false})
  };

  return (
    <React.Fragment>
      <LoadingContainer className="mb-4" loading={loading}>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle>{props.t("EDIT_USER")}</CardTitle>
                <CardSubtitle className="mb-3">
                  {props.t("PLEASE_FILL_USERS_DETAIL")}
                </CardSubtitle>
                <Form onSubmit={(e) => handleSubmitForm(e)}>
                  {error.length ? (
                    <Alert color="danger">
                      {error}
                    </Alert>
                  ) : null}
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <div className="mb-3">
                        <Label className="form-label">{props.t("FIRST_NAME")}</Label>
                        <Input
                          id="firstname"
                          name="firstname"
                          className="form-control"
                          placeholder={props.t("FIRST_NAME")}
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.firstname || ""}
                        />
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("NAME")}</Label>
                        <Input
                          id="lastname"
                          name="lastname"
                          className="form-control"
                          placeholder={props.t("NAME")}
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.lastname || ""}
                          invalid={
                            validation.touched.lastname && validation.errors.lastname ? true : false
                          }
                        />
                        {validation.touched.lastname && validation.errors.lastname ? (
                          <FormFeedback
                            type="invalid">{validation.errors.lastname}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("INITIALS")}</Label>
                        <Input
                          id="initials"
                          name="initials"
                          className="form-control"
                          placeholder={props.t("INITIALS")}
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.initials || ""}
                          invalid={
                            validation.touched.initials && validation.errors.initials ? true : false
                          }
                        />
                        {validation.touched.initials && validation.errors.initials ? (
                          <FormFeedback
                            type="invalid">{validation.errors.initials}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("EMAIL")}</Label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder={props.t("EMAIL")}
                          type="email"
                          disabled={false}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback
                            type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label
                          className="form-label">{props.t("USERNAME")}</Label>
                        <Input
                          id="username"
                          name="username"
                          className="form-control"
                          placeholder={props.t("USERNAME")}
                          type="text"
                          disabled={false}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ""}
                          invalid={
                            validation.touched.username && validation.errors.username ? true : false
                          }
                        />
                        {validation.touched.username && validation.errors.username ? (
                          <FormFeedback
                            type="invalid">{validation.errors.username}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mt-4">
                        <CardTitle>{props.t("STATUS")}</CardTitle>
                        <div className="form-check form-switch form-switch-lg mb-3">
                          <input
                            id="enabled"
                            name="enabled"
                            className="form-check-input"
                            type="checkbox"
                            onClick={(e) => toogleEnable(e)}
                            checked={enabled}
                            value={1}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customSwitchsizelg"
                          >
                            {enabled ? props.t('DISABLE_USER') : props.t('ENABLE_USER')}
                          </label>
                        </div>
                      </div>

                      <div className="mb-3">
                        <Label
                          className="form-label">{props.t("PASSWORD")}</Label>
                        <Input
                          id="password"
                          name="password"
                          className="form-control"
                          placeholder={props.t("PASSWORD")}
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid"> { '<p>' + validation.errors.password + '</p>'}</FormFeedback>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary">
                      {props.t("SAVE_MODIFICATION")}                    
                    </button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LoadingContainer>
    </React.Fragment>
  )
};
export default withTranslation()(EditUser);
