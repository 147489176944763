import AbstractFoundation from "@entity/AbstractFoundation";

/**
 * Class Access.
 * Represente un utilisateur courant identifié
 *
 * @link       http://github.com/bssoufo
 * @author     Bruno Soufo <bssoufo@gmail.com>
 */

export default class Access extends AbstractFoundation {
  public email: string;
  public username: string;
  public lastname: string;
  public firstname: string;
  public initials: string;
  public password: string;
  public token?: string;
  public companyId?: string;
  public companyName?: string;
  public langue?: string;
  public enabled: number;
  public permissions: string[];

  /**
   * Token supprimé lors de l'inscription
   * @param token
   */
  public constructor(token: any = {}) {
    super();
    this.email = '';
    this.username = '';
    this.lastname = '';
    this.firstname = '';
    this.initials = '';
    this.token = '';
    this.enabled = 0;
    this.langue ='';

    this.companyId = ";"
    this.companyName = ";"
    
    this.permissions = [];
  }

  public toString(locale?: string): string {
    throw new Error("Method not implemented.");
  }
}
