import UserProvier from '@app/services/UserProvier';
import { HTTP_RESPONSE } from '@app/utils/constants'
import axios from 'axios'
import { string } from 'yup'

export default class RoleRepository {
  protected api: string
  protected apiRolePermissionUrl : string;

  public constructor(api: string, apiRolePermissionUrl:string) {
    this.api = api
    this.apiRolePermissionUrl = apiRolePermissionUrl

    UserProvier.setToken();
  }

  queries = (itemPerPage = 15, page = 1, search = '', querying = '') => {
    return (
      this.api +'?filter[where][or][0][name][like]=%'+search+'%' +'&filter=[where][name][options]=i' +
      '&filter[where][or][1][description][like]=%'+search+'%' +'&filter=[where][description][options]=i' +
      '&filter[limit]=' + itemPerPage +
      '&filter[skip]=' + ((page - 1) * itemPerPage > 0 ? (page - 1) * itemPerPage : 0)
    )
  }

  queriesIncludeAll = (id:string) => {
    return (
      this.api +'?filter[where][id]=' + id +
      '&filter[include][]=permissions'
    )
  }

  add = (item: any) => {
    return axios
      .post(this.api, item)
      .then((response: { data: any }) => {
        return response.data
      })
      .catch((e: { response: any }) => {
        if (e.response) {
          throw e.response
        } else {
          throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
        }
      })
  }

  edit = (item: any) => {
    return axios
      .put(this.api + '/' + item.id, item)
      .then((response) => {
        return response.data
      })
      .catch((e) => {
        if (e.response) {
          throw e.response
        } else {
          throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
        }
      })
  }

  findAll = (page = 1, itemPerpage = 10, search = '') => {
    return axios
      .get(this.queries(itemPerpage, page, search))
      .then((response: { data: any }) => {
        return response.data
      })
      .catch((e: { response: any }) => {
        if (e.response) {
          throw e.response
        } else {
          throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
        }
      })
  }

  flush = (item:  any ) => {
    if (item.id) {
      return this.edit(item)
    } else {
      return this.add(item)
    }
  }

  find = (id: string) => {
    return axios
      .get(this.api + '/' + id)
      .then((response: { data: any }) => {
        return response.data
      })
      .catch((e: { response: any }) => {
        if (e.response) {
          throw e.response
        } else {
          throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
        }
      })
  }

  findIncludeAll = (id: string) => {
    return axios
      .get(this.queriesIncludeAll(id))
      .then((response: { data: any }) => {
        return response.data
      })
      .catch((e: { response: any }) => {
        if (e.response) {
          throw e.response
        } else {
          throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
        }
      })
  }

  remove = (id: string) => {
    return axios
      .delete(this.api + '/' + id)
      .then((response: any) => {
        return id
      })
      .catch((e: { response: any }) => {
        if (e.response) {
          throw e.response
        } else {
          throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
        }
      })
  }

  deletePermission = ( role: string, permission: string) => {
    return axios.delete(this.apiRolePermissionUrl + '/' + role +'/' + permission)
    .then((response : any) => {

    })
    .catch((e: { response: any }) => {
      if (e.response) {
        throw e.response
      } else {
        throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
      }
    })
  }

  addPermission = ( item: {roleId : number, permissionId: number}) => {
    return axios.post(this.apiRolePermissionUrl, item)
    .then((response : any) => {
      return response.data 
    })
    .catch((e: { response: any }) => {
      if (e.response) {
        throw e.response
      } else {
        throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
      }
    })
  }
}
