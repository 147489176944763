import { HTTP_RESPONSE } from "@app/utils/constants";
import axios from "axios";

export default class ReferenceLabelRepository {
    protected api: string
  
    public constructor(api: string) {
      this.api = api
    }
  
    queries = (itemPerPage = 15, page = 1, search = '', referenceValueId = 0) => {
      return (
        this.api +'?filter[where][or][0][label][like]=%'+search+'%' +'&filter=[where][label][options]=i' +
        '&filter[include][]=langue&filter[include][]=referenceValue'+
        '&filter[limit]=' + itemPerPage +
        (referenceValueId ? '&filter[where][and][0][referenceValueId]='+referenceValueId : '' ) +
        '&filter[skip]=' + ((page - 1) * itemPerPage > 0 ? (page - 1) * itemPerPage : 0)
      )
    }
  
    queriesIncludeAll = (id:string) => {
      return (
        this.api +'?filter[where][id]=' + id +
        '&filter[include][]=langue&filter[include][]=referenceValue'
      )
    }

    add = (item: any) => {
      return axios
        .post(this.api, item)
        .then((response: { data: any }) => {
          return response.data
        })
        .catch((e: { response: any }) => {
          if (e.response) {
            throw e.response
          } else {
            throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
          }
        })
    }
  
    edit = (item: any) => {
      return axios
        .put(this.api + '/' + item.id, item)
        .then((response) => {
          return response.data
        })
        .catch((e) => {
          if (e.response) {
            throw e.response
          } else {
            throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
          }
        })
    }
  
    findAll = (page = 1, itemPerpage = 10, search = '',referenceValueId=0) => {
      return axios
        .get(this.queries(itemPerpage, page, search,referenceValueId))
        .then((response: { data: any }) => {
          return response.data
        })
        .catch((e: { response: any }) => {
          if (e.response) {
            throw e.response
          } else {
            throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
          }
        })
    }

    findIncludeAll = (id: string) => {
      return axios
        .get(this.queriesIncludeAll(id))
        .then((response: { data: any }) => {
          let tmp = response.data.items;
          if ( tmp.length > 0 ){
              return tmp[0];
          }else{
              return null;
          }
        })
        .catch((e: { response: any }) => {
          if (e.response) {
            throw e.response
          } else {
            throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
          }
        })
    }
  
    flush = (item:  any ) => {
      if (item.id) {
        return this.edit(item)
      } else {
        return this.add(item)
      }
    }
  
    find = (id: string) => {
      return axios
        .get(this.api + '/' + id)
        .then((response: { data: any }) => {
          return response.data
        })
        .catch((e: { response: any }) => {
          if (e.response) {
            throw e.response
          } else {
            throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
          }
        })
    }
  
    remove = (id: string) => {
      return axios
        .delete(this.api + '/' + id)
        .then((response: any) => {
          return id
        })
        .catch((e: { response: any }) => {
          if (e.response) {
            throw e.response
          } else {
            throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
          }
        })
    }
}