import AbstractRepository from "@repository/AbstractRepository";
import Access from "@entity/user/Access";
import axios from "axios";
import {HTTP_RESPONSE} from "@utils/constants";

/**
 * Class UserRepository.
 * Permettant de se connecter à l'API des specialités de compétences
 *
 * @link       http://github.com/bssoufo
 * @author     Bruno Soufo <bssoufo@gmail.com>
 */
export default class UserRepository extends AbstractRepository<Access> {

  /**
   * Permet de netoyyer l'objet avant son envoie sur le server
   *
   * @param item
   * @param isAdd
   * @return Message
   */
  protected clear(item: Access, isAdd: boolean = true): Access {
    if (isAdd){
      delete item['id'];
    }
    delete item['previous'];
    delete item['dateAdd'];
    delete item['dateUpd'];
    delete item['token'];
    return item;
  }


  /**
   * Savegarde des changements sur une entité.
   *
   * @param data
   * @return Promise<T>
   */
  public login(data: any): Promise<Access> {
    return axios
      .post(this.api + '/', data)
      .then((response:any) => {
        return this.parseLogin(response.data)
      })
      .catch((e: any) => {
        if (e.response) {
          throw e.response
        } else {
          throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
        }
      })
  }

  /**
   * Permet de renvoyer un objet hydrater.
   *
   * @param data
   * @return Access
   */
  public parse(data: any): Access {
    const entity = new Access(data.id);
    entity.email = data.email;
    entity.firstname = data.firstname;
    entity.lastname = data.lastname;
    entity.enabled = data.enabled;
    entity.username = data.username;
    entity.initials = data.initials;
    entity.langue = data.langue;

    entity.companyId = data.companyId
    entity.companyName = data.companyName
    if(data.token)
      entity.token = data.token;

    entity.permissions = data.permissions;
    return this.fieldsWalker(entity, data);
  }



  /**
   * Permet de renvoyer un objet hydrater.
   *
   * @param data
   * @return Access
   */
  public parseLogin(data: any): Access {
    const entity = new Access(data.id);
    entity.email = data.email;
    entity.firstname = data.firstname;
    entity.lastname = data.lastname;
    entity.enabled = data.enabled;
    entity.username = data.username;
    entity.permissions = data.permissions;
    entity.langue = data.langue;

    entity.companyId = data.companyId
    entity.companyName = data.companyName

    if(data.token)
      entity.token = data.token;
    if(data.internalId)
      entity.id = data.internalId;
    return entity;
  }

  public register(item:any) : Promise<Access> {
    return axios
      .post(this.api + '/register', item)
      .then((response:any) => {
        return this.parse(response.data)
      })
      .catch((e: any) => {
        if (e.response) {
          throw e.response
        } else {
          throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
        }
      })
  }

  public verify(code: string): Promise<any> {
    return axios
      .get(this.api + '/verify/' + code)
      .then((response) => {
        return response.data
      })
      .catch((e: any) => {
        if (e.response) {
          throw e.response
        } else {
          throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
        }
      })
  }
}
