import { HTTP_RESPONSE } from "@app/utils/constants";
import axios from "axios";

export default class ReferenceCompanyRepository {
    protected api: string
  
    public constructor(api: string) {
      this.api = api
    }
  
    queries = (itemPerPage = 15, page = 1, search = '') => {
      return (
        this.api +'?filter[where][or][0][name][like]=%'+search+'%' +'&filter=[where][name][options]=i' +
        '&filter[where][or][1][description][like]=%'+search+'%' +'&filter=[where][description][options]=i' +
        '&filter[limit]=' + itemPerPage +
        '&filter[skip]=' + ((page - 1) * itemPerPage > 0 ? (page - 1) * itemPerPage : 0)
      )
    }
  
    add = (item: any) => {
      return axios
        .post(this.api, item)
        .then((response: { data: any }) => {
          return response.data
        })
        .catch((e: { response: any }) => {
          if (e.response) {
            throw e.response
          } else {
            throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
          }
        })
    }
  
    edit = (item: any) => {
      return axios
        .put(this.api + '/' + item.id, item)
        .then((response) => {
          return response.data
        })
        .catch((e) => {
          if (e.response) {
            throw e.response
          } else {
            throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
          }
        })
    }
  
    public findAll = (page = 1, itemPerpage = 10, search = '') => {
      return axios
        .get(this.queries(itemPerpage, page, search))
        .then((response: { data: any }) => {
          return response.data
        })
        .catch((e: { response: any }) => {
          if (e.response) {
            throw e.response
          } else {
            throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
          }
        })
    }
  
    flush = (item:  any ) => {
      if (item.id) {
        return this.edit(item)
      } else {
        return this.add(item)
      }
    }
  
    find = (id: string) => {
      return axios
        .get(this.api + '/' + id)
        .then((response: { data: any }) => {
          return response.data
        })
        .catch((e: { response: any }) => {
          if (e.response) {
            throw e.response
          } else {
            throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
          }
        })
    }
  
    remove = (id: string) => {
      return axios
        .delete(this.api + '/' + id)
        .then((response: any) => {
          return id
        })
        .catch((e: { response: any }) => {
          if (e.response) {
            throw e.response
          } else {
            throw { ...e, status: HTTP_RESPONSE.HTTP_NOT_FOUND }
          }
        })
    }
}