import React, {Fragment, FunctionComponent} from 'react'
import AsyncSelect from 'react-select/async';
import {DEFAULT_APP_LOCALE} from "@config/parameter";
import {LocaleInterface} from "@app/core/types/locale.interface";
import AbstractFoundation from "@entity/AbstractFoundation";
import InputChoiceFormInterface from "@app/core/types/form.choice.props";

interface InputAsynChoiceFormPropsInterface extends InputChoiceFormInterface {
    remoteChoices: any,
    choiceAttr:string,
    noOptionMessage?: string,
    locale?: LocaleInterface,
    getOptionLabel?:(option : any)=>string;
}

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const AutocompleteSelectType: FunctionComponent<InputAsynChoiceFormPropsInterface> = (props) => {
    const {id, name, remoteChoices, choiceAttr="name", onChange, error = '', cssClass = 'w-100', value , placeholder = '',getOptionLabel, showErrorText = true, noOptionMessage = 'Aucune entrée trouvée', multiple = false, locale = DEFAULT_APP_LOCALE, disabled=false} = props;
    const defaultValue = value ? value : placeholder;
    return (
        <Fragment>
            <AsyncSelect
                onChange={(e: any) => {
                    const target = {
                        'name': name,
                        'value': e || []
                    };
                    onChange({target: target, e: e});
                }}
                isMulti={multiple}
                hideSelectedOptions={false}
                cacheOptions={false}
                isDisabled={disabled}
                // cacheOptions={true}
                // hideSelectedOptions={true}
                isClearable={true}
                getOptionLabel={getOptionLabel?getOptionLabel:(opt) => opt[choiceAttr]}
                getOptionValue={(opt: any) => opt.id}
                defaultOptions={true}
                defaultValue={defaultValue}
                value={defaultValue}
                className={cssClass}
                placeholder={placeholder}
                loadOptions={remoteChoices}
            />
            {(error && showErrorText) && (
                <p className="invalid-feedback d-block">
                    {error}
                </p>)}
        </Fragment>
    );
};
export default AutocompleteSelectType;
